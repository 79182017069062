// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import styled from 'styled-components';
import TopHeader from '../common/topHeader';
import ScrollButton from './top/backToTop/ScrollButton';

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: 30px;
  margin-bottom: 40px;
  color: #464646;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 20px;
  }
`;

const Content = styled.div`
  font-size: 14px;
  letter-spacing: 0.1em;
  line-height: 24px;
  margin-bottom: 30px;
  color: #464646;

  @media screen and (max-width: 768px) {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 15px;
  }
`;

export default function Terms() {
  return (
    <>
      <TopHeader title="KuZiPA! 利用規約" />
      <div className="main-content form-static">
        <Title>「KuZiPA!」利用規約</Title>
        <Content>
          「KuZiPA!」利用規約（以下「本規約」といいます。）とは、株式会社CREST（以下「当社」といいます。）が提供する、第1条で定義する「本サービス」に関して、当社と登録者様との間の関係を規定するものです。「本サービス」に登録して頂くには、登録画面から登録を希望し登録者となっていただかなくてはならないため、以下の条項をお読みの上、同意して頂く事が必要です。又、未成年者の方々は、法定代理人（親権者等）の同意を必ず得る必要があります。
        </Content>
        <Content>
          第１条（定義）
          <br />
          本規約で使用する用語の意味は、以下のとおりとします。
          <br />
          １．「登録者」とは、「KuZiPA!」の利用を希望するユーザー様が本規約に同意いただいた上で、当社所定の手続に従い本サービスの登録画面から登録を申請し、当社がこれを承認した方をいいます。
          <br />
          ２．「本サイト」とは、当社が運営するインターネット・サイト「KuZiPA!」をいいます。
          <br />
          ３．「本サービス」とは、本サイトにおける、登録者に向けた商品（くじ）の販売、登録者特典の提供その他のサービス全般を指します。
          <br />
          ４．「+On
          Challenge」とは、「KuZiPA!」内の個別のサービス内にて、購入した同一タイトルのくじ商品とは別の自動抽選機能で、購入したくじでもらえる商品とは別に、自動抽選されて当せんする可能性のある賞です。
          <br />
          ５．「プラチナチケット」とは、「KuZiPA!」内の個別のサービス内にて、同一タイトルのくじを累計100枚引くごとに、好きな景品1個と交換できるチケットです。
          <br />
          ６．「登録情報」とは、ユーザー様が登録者として当社に提供した個人情報等の情報をいいます。
          <br />
          ７．「商品（景品）」とは、本サイトを通じて当社が定める内容に応じて提供するサービス「KuZiPA!」にて販売する「くじ」及びそれらから得られる物品等をいいます。
        </Content>
        <Content>
          第２条（本サービスの利用及び責任）
          <br />
          １．登録者は、登録者が登録した登録者のメール・アドレスと登録者が設定したパスワードを利用して本サービスを利用することができます。
          <br />
          ２．登録者は、本規約及び当社が別途定めるルール等に従い、登録者自身の自己責任において本サービスを利用するものとし、本サービスを利用してなされた一切の行為及びその結果について、登録者は一切の責任を負うものとします。
          <br />
          ３．当社は、登録者を、登録情報に基づき管理し、本サービスの提供を行うものとします。
          <br />
          ４．登録者は、本サービスの利用に伴い発生する通信費等を全て自己負担するものとします。
          <br />
          ５．登録者は、登録者の責めに帰すべき事由により、当社又は第三者に損害が生じた場合、全ての責任を負うものとします。
        </Content>

        <Content>
          第３条（登録）
          <br />
          １．登録希望者は、登録者となった上で、本サービスの登録ページから、別途当社の指定する方法に従い登録申請を行うものとします。また、当社からの仮登録の通知より登録を行い、その後の登録完了の通知をもって登録の承認とさせていただきます。尚、登録完了までにはお時間がかかる場合がございます。
          <br />
          ２．当社は、以下の各号のいずれかに該当する場合、登録の承認を拒み又は取消すことができるものとします。
          <br />
          （１）登録希望者が過去に本規約等に反する行為をしたこと等により、登録の取消等の処分を受けていることが判明した場合
          <br />
          （２）登録希望者の申請内容に事実に反する事項が含まれている場合
          <br />
          （３）登録希望者が反社会的勢力等（暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋、社会運動標ぼうゴロ、政治活動標ぼうゴロ、特殊知能暴力集団又はこれらの者と密接な関わりを有する者若しくはこれらに準じる者）のいずれかと、何らかの交流若しくは関与があると当社が判断した場合
          <br />
          （４）その他登録希望者の登録申請を承認することが不適当であると当社が判断する場合
        </Content>

        <Content>
          第４条（商品（くじ）の購入）
          <br />
          １．登録者は、本サービスを利用して、商品（くじ）又はサービス（以下「商品（くじ）等」といいます。）を購入することができます。
          <br />
          ２．登録者は、商品（くじ）等の購入を希望する場合、当社が指定する方法に従って商品（くじ）等の購入を申込むものとします。
          <br />
          ３．商品（くじ）等購入に関する売買契約は、決済完了メール（登録者により登録されたメールアドレス宛に｢決済完了のお知らせ｣が送られます。）が登録者に送信された時点で成立することとします。尚、当社は、売買契約成立前にご注文をお断りすることがあります。
          <br />
          ４．当社は、個別のサービス画面又はご利用ガイドその他の本サービス上の画面において定めがある場合を除き、登録者都合での商品（くじ）等の購入申込の撤回は一切受け付けないものとします。
          <br />
          ５．当社では、正確な商品（くじ）等の情報を掲載するよう最善の努力をしますが、万が一誤った価格表記がされており、それに基づいた注文をいただいた場合は、発送完了メール送信までに当社から変更の通知を行い、改めて変更後の価格でその注文の意思を確認させていただきます。
          <br />
          ６．本サービス利用に関して登録者による不正行為若しくは不適当な行為があった場合、又は当社が配送した商品を、理由の如何を問わず登録者が受領しなかった場合（登録者が商品代金を支払い済みの場合を含みます。）、当社は売買契約の解除、その他適切な措置を取ることができるものとします。
          この場合に当社に生じた費用（商品の配送料その他手数料を含みますが、これらに限りません。）は登録者の負担とします。
          <br />
          ７．本サービスによる商品の配送先は、日本国内に限ります。
          <br />
          ８．インターネット上の障害、その他当社に責任のない原因により、商品（くじ）等の購入申込みが受理できなかった場合、当社はその責任を負いません。
        </Content>

        <Content>
          第５条（商品の配送等）
          <br />
          １．商品の配送は、当社が指定する配送業者による配送便で行います。尚、配送料は、商品の注文画面の指定に従い登録者のご負担となります。また、配達の日時指定については、別段の定めがない限り、できません。
          <br />
          ２．商品によっては、諸般の事情で、予めご案内した納期が変更される場合や品切れ等により納期が遅れる場合、また注文に応じられない場合もあります。これらの場合でも、当社は何ら賠償の責任を負いません。
          <br />
          ３．当社が発送した商品が登録者により受領されなかった場合（受取拒否又は長期不在等により受け取られなかった場合を含みます。）、当該登録者には、これによって当社に生じた費用（再発送費用等）をお支払いいただく場合があります。
          <br />
          ４．当社は、登録者により受領されなかった商品を一定期間保管後に廃棄その他の処分をすることができることとします。
          <br />
          ５．登録者は、本サイトで表示されるくじ（自体）は登録者に発送される物品等を決定するためのものであり、くじそのもの自体は、登録者（自身）には発送されないことを確認し了承することとします。
        </Content>

        <Content>
          第６条（決済方法）
          <br />
          １．商品（くじ）等の購入のお支払い金額は、商品（くじ）等の購入代金、送料及び消費税等の合計となります。
          <br />
          ２．本サービスによって購入された商品（くじ）等のお支払いに関しては、登録者本人名義のクレジットカード（当社が承諾するものに限ります。）によるお支払い、その他当社が別途認めるお支払方法によるものとします。
          <br />
          ３．クレジットカードでのお支払いの場合は、登録者がカード会社との間で別途契約する条件に従うものとします。また、登録者と当該カード会社の間で紛争が発生した場合は、当該当事者双方で解決するものとし、当社には一切責任はないものとします。
          <br />
          ４．当社は、クレジットカードでお支払いの場合、商品（くじ）等の購入の申込時にカード会社へご利用可能の問い合わせを行います。尚、購入申込から商品発送までに一定の期間を要する場合、再度クレジットカード会社へご利用可能の問い合わせをさせていただく場合があります。これらの問い合わせの結果、カード会社よりご利用不可との連絡が入った場合、ご購入いただけない場合があります。
        </Content>

        <Content>
          第７条（商品（くじ）等の返品等）
          <br />
          １．売買契約成立後のキャンセル、商品（くじ）等のキャンセル、返品及び交換等（以下「キャンセル等」といいます。）は、商品不良、品違い又は数量違い等の当社の責めに帰すべき場合にのみ承ります。
          <br />
          ２．登録者は、前項のキャンセル等を、当社が別途定める方法に従い行うものとします。
        </Content>

        <Content>
          第８条（サービスに関する免責）
          <br />
          １．商品（景品）等の色合い、形状については、モニターの設定等により、画面表示と実物とで異なる場合があります。
          <br />
          ２．当社は、登録者が別途登録した連絡先や住所等が不正確であったことにより登録者に生じた損失について一切補償しないものとします。
          <br />
          ３．当社は、本サービス上での商品説明あるいは表記については、正確性、完全性、最新性等に関し最善を尽くしますが、一切誤りがないことを保証するものではありません。
          <br />
          ４．当社は、法律上の請求原因の如何を問わず、本サービスの利用から生じる如何なる損害、損失、不利益に関して責任を負わないものとします。また、本サービスにおいて売買される商品（くじ）等から生じた損害、損失、不利益に関して責任を負わないものとします。
        </Content>

        <Content>
          第９条（特典）
          <br />
          １．「プラチナチケット」
          <br />
          プラチナチケットは、「KuZiPA!」内の個別のサービスページ内にて、同一タイトルのくじを累計100枚引くごとに、好きな景品1個と交換できるチケットです。当該チケットは登録者のMyコレクション内に明記されます。チケットの付与方法は予告なく変更となる場合があります。
          <br />
          ２．「+On Challenge」
          <br />
          「+On
          Challenge」は、「KuZiPA!」内の個別のサービス・ページ内にて、購入した同一タイトルのくじ商品とは別の自動抽選機能で、購入したくじでもらえる商品とは別に、自動抽選されて当せんする可能性のある賞です。当該自動抽選は本サイト上の個別のサービス・ページに表示されます。当該自動抽選機能は、当せんを確約するものではありません。
        </Content>

        <Content>
          第１０条（チケットの利用）
          <br />
          登録者は、保有するチケットを、上記第９条で定めるルール等に従いご利用いただけます。
        </Content>

        <Content>
          第１１条（チケットの失効等）
          <br />
          １．登録者が退会した場合、その時点でチケットは失効します。
          <br />
          ２．当社は、予告なくチケット制度を変更・廃止することが出来るものとします。
          <br />
          ３．当社は、失効したチケットについて何らの補償を行わず、一切の責任を負いません。
        </Content>

        <Content>
          第１２条（チケットの譲渡禁止）
          <br />
          付与されたチケットは、如何なる場合にも、登録者間での移動、譲渡、貸与、累積等はできません。
        </Content>

        <Content>
          第１３条（第三者による使用）
          <br />
          １．チケットの利用は、登録者本人が行うものとし、当該登録者以外の第三者が行うことはできません。
          <br />
          ２．当社は、チケット利用時に入力された登録者のメール・アドレス及びパスワードが、登録者登録されたものと一致することを当社が所定の方法により確認した場合には、当該登録者による利用とみなします。それが第三者による不正利用であった場合でも、当社は利用されたチケットを返還しません。又、登録者に生じた損害についても一切責任を負いません。
        </Content>

        <Content>
          第１４条（本サービスの利用停止、登録者登録の取消、ポイントの抹消）
          <br />
          １．当社は、登録者が以下の各号のいずれかに該当する場合、登録者に事前通知することなく本サービスの利用停止、登録者登録の取消又はチケットの一部若しくは全部を抹消することができるものとします。
          <br />
          （１）過去に本規約等に違反をしたこと等により登録者登録の取消等の処分をうけていることが判明した場合
          <br />
          （２）本サービスに関する料金等の支払債務の履行遅延、その他の不履行があった場合
          <br />
          （３）死亡又は行為能力を失った場合
          <br />
          （４）登録者でなくなった場合
          <br />
          （５）第１５条（禁止事項）の行為を行った場合
          <br />
          （６）その他本規約又は当社が別途定めるルール等に違反した場合
          <br />
          ２．前項により登録者登録が取消された場合、それ以降、本サービスは利用することができなくなります。
        </Content>

        <Content>
          第１５条（禁止事項）
          <br />
          登録者は以下の行為を行ってはならないものとします。
          <br />
          （１）本サービス利用の際に虚偽の登録内容を申請する行為
          <br />
          （２）本サービスの運営を妨げ、その他本サービスに支障をきたすおそれのある行為
          <br />
          （３）不正な手段を用いて本サービスを利用する行為
          <br />
          （４）メール・アドレス及びパスワードを不正に使用する行為
          <br />
          （５）法的な責任を超えた不当な要求行為
          <br />
          （６）他の登録者、第三者若しくは当社に迷惑、不利益若しくは損害を与える行為、又はそれらのおそれのある行為
          <br />
          （７）他の登録者、第三者若しくは当社の著作権等の知的財産権、プライバシーその他の権利を侵害する行為、又はそれらのおそれのある行為
          <br />
          （８）本規約に違反する行為、公序良俗に反する行為その他法令に違反する行為、又はそれらのおそれのあると当社が判断した行為
          <br />
          （９）その他、当社が不適当と判断する行為
        </Content>

        <Content>
          第１６条（メール・アドレス及びパスワードの管理）
          <br />
          １．登録者は、登録者メール・アドレス及びパスワードの管理責任を負うものとします。
          また、登録者メール・アドレス及びパスワードを用いて当社に対して行われた意思表示は、当該登録者メール・アドレス及びパスワードに該当する登録者の有効な意思表示とみなし、これにより生ずる支払義務等は全て当該登録者の責任となります。
          <br />
          ２．登録者は、登録者メール・アドレス及びパスワードを第三者に譲渡、貸与、開示してはならないものとします。
          <br />
          ３．登録者は、登録者メール・アドレス及びパスワードの管理不十分、使用上の過誤、第三者の使用等に起因する損害につき自ら責任を負うものとします。
          <br />
          ４．登録者は、登録者メール・アドレス及びパスワードが第三者によって不正に使用されていることが判明した場合には、直ちに当社に連絡するものとします。
        </Content>

        <Content>
          第１７条（変更の届出）
          <br />
          登録者は、住所、氏名、電話番号、メール・アドレスその他当社に届出ている事項に変更が生じた場合には、当社が別途定める方法により、すみやかに当社に届け出るものとします。
        </Content>

        <Content>
          第１８条（退会）
          <br />
          登録者は、登録者の都合により退会を希望する場合は、当社が別途定める方法により、いつでも退会できます。尚、未発送商品がある場合は、当社による当該商品の発送及びこれに対する登録者のお支払いを全て終えるまで、本規約に基づく登録者の義務は存続するものとします。登録者メール・アドレス、パスワードを忘れ、退会できない方は、当社所定の方法にてパスワードの再発行を受けるものとします。
        </Content>

        <Content>
          第１９条（秘密保持）
          <br />
          登録者は、本サービスに関連して当社が登録者に対して秘密に取り扱うことを求めて開示した非公知の情報について、厳に秘密として取り扱うものとします。
        </Content>

        <Content>
          第２０条（著作権）
          <br />
          １．本サービスに関する著作権等の知的財産権（著作権法第２７条、第２８条に定める権利を含みます。）は、全て当社又は当社が許諾を受けた第三者に帰属するものとします。
          <br />
          ２．登録者は、権利者の許諾を得ないで、本サービスを通じて提供される如何なる情報も、著作権法第３０条で認められる個人の私的複製等の制限規定範囲外での使用をすることはできません。
          <br />
          ３．本条の規定に違反して問題が生じた場合、登録者は自己の責任と費用においてかかる問題を解決するとともに、当社に何等の迷惑又は損害を与えないものとします。
        </Content>

        <Content>
          第２１条（本サービス内容の変更、停止、終了等）
          <br />
          １．当社が必要と判断した場合には、登録者に事前に通知することなく、いつでも本サービス内容について変更することができ、又、チケットの停止、若しくは中止、中断、終了、及び付与条件の変更等（以下「変更等」といいます。）を行うことができるものとします。但し、本サービスに関して登録者の権利や義務に重大な影響が生じる変更等については、合理的な予告期間を設けた上で、変更等を行うものとします。
          <br />
          ２．前項のような変更等を行った場合であっても、当社は登録者に対しては一切責任を負わないものとします。
          <br />
          ３．当社が必要と判断した場合には、本サイト上に告知することで、いつでも本サービスを廃止することができるものとします。
          <br />
          ４．本サービス廃止の場合、登録者の取得したチケットは無効となります。
        </Content>

        <Content>
          第２２条（個人情報）
          <br />
          当社は、本サービスの提供のため、当社が登録者から取得した個人情報等に関しては、当社のプライバシーポリシー＜プライバシーポリシー
          - 株式会社CREST / CREST Inc. (<a href="https://kuzipa@crest-inc.net">crest-inc.net</a>
          )＞に従い取り扱うものとします。
        </Content>

        <Content>
          第２３条（その他免責事項）
          <br />
          １．当社は、以下の場合に一時的に本サービスの提供を停止、中止、中断等の措置を行うことがあります。これにより登録者が本サービスの全部又は一部をご利用になれず、登録者に損害が生じた場合においても、当社は当該損害について如何なる責任も負わないものとします。
          <br />
          （１）本サービスの障害、保守又はメンテナンスを緊急に行う必要がある場合
          <br />
          （２）天災地変、自然災害、停電等の不可抗力により本サービスの運営が出来なくなった場合
          <br />
          （３）通信回線等の事故により本サービスが停止した場合
          <br />
          ２．当社は、登録者の登録情報に従い事務を処理することにより登録者に損害が生じても一切責任を負わないものとします。
          <br />
          ３．登録者が、本サービスをご利用になることにより、他の登録者又は第三者に対して損害等を与えた場合には、当該登録者は自己の責任と費用において解決し、当社には一切迷惑を与えないものとします。
        </Content>

        <Content>
          第２４条（損害賠償の範囲）
          <br />
          １．本規約の規定にかかわらず、登録者による本サービスの利用に関し、消費者契約法が適用される場合、かつ登録者に生じた損害に当社の責めに帰すべき事由がある場合、当社は、以下の区分に従い、賠償するものとします。
          <br />
          （１）登録者に生じた損害が当社の故意又は重過失に起因する場合は、民法等の規定に基づく責任
          <br />
          （２）登録者に生じた損害が当社の故意又は重過失に起因しない場合は、登録者が直接かつ現実に被った損害を第６条第１項の金額を上限とした責任（付随的損害、間接損害、特別損害及び逸失利益に関する損害については、責任を負わないものとします。）
          <br />
          ２．登録者が被った損害が、登録者が本規約に違反する等、登録者の責に帰すべき事由（当該事由に基づく当社の作為又は不作為を含みます。）に起因する場合、当社は当該損害について責任を負わないものとします。
        </Content>

        <Content>
          第２５条（連絡方法）
          <br />
          登録者から当社への連絡は、当社指定の問合せフォームから行われるものとします。
        </Content>

        <Content>
          第２６条（本規約の範囲及び変更）
          <br />
          １．本規約は本サービスの利用に関し、本サービスを利用される登録者に適用されるものとします。但し、本サービス利用において、本規約と異なる別段の条件がある場合には、本規約に優先してその条件が適用されるものとします。
          <br />
          ２．当社は、登録者に事前の承諾を得ることなく、随時本規約を変更できるものとします。また、当社が別段の定めをする場合を除き、変更後の本規約は本サイトへ掲載する等により登録者に通知した時に効力を生じるものとします。なお、本規約の変更後に登録者が本サービスを利用した場合には、変更後の規約に同意したものとみなします。
        </Content>

        <Content>
          第２７条（分離可能性）
          <br />
          本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効とされた場合であっても、本規約の残りの規定及び一部が無効と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
        </Content>

        <Content>
          第２８条（準拠法及び合意管轄）
          <br />
          １．本サービス及び本規約は、日本法に準拠して解釈されるものとします。
          <br />
          ２．本サービス及び本規約において当社と登録者の間に紛争が発生した場合には、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
        </Content>

        <Content>2022年4月19日　制定</Content>
      </div>
      <ScrollButton />
    </>
  );
}
