// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import rankA from '../../../../assets/imgs/lotteryDetail/rankA.png';
import rank from '../../../../assets/imgs/lotteryDetail/rank.png';
import { useTranslation } from 'react-i18next';
import { setBackGroundLineUp } from '../../../utils/setBackGroundLineUp';
import { RANK_A } from '../../../constants';
import { convertRankName } from '../../../utils/convertSaleStatus';

function LineUpHeader(props) {
  const { t } = useTranslation();
  const { prizeGroup } = props;

  return (
    <div className={`my-line-up-header ${setBackGroundLineUp(prizeGroup.rank_name).background}`}>
      <div className="line-up-line" />
      <div className="my-line-up-content">
        <div className="my-rank-name">
          <div className="logo-rank-name">
            <div className={prizeGroup.rank_name === RANK_A ? 'rank-name rank-a' : 'rank-name'}>
              {convertRankName(prizeGroup.rank_name)}
              <span>賞</span>
            </div>
            <img src={prizeGroup.rank_name === RANK_A ? rankA : rank} alt="logo" />
          </div>

          <div className="wrap-title-prize">
            <div className="title-prize">{prizeGroup.title}</div>
            <div
              className={`tag-quantity-content  ${setBackGroundLineUp(prizeGroup.rank_name).color}`}
            >
              {t('quantityPrize').replace('param', prizeGroup.quantity_prize)}
            </div>
          </div>
        </div>

        <div className={`line-up-win-rate ${setBackGroundLineUp(prizeGroup.rank_name).bgWinRate}`}>
          <div className="text">{t('winRate')}</div>
          <div className="win-rate-value">
            <span>{prizeGroup.win_rate}</span>/100
          </div>
        </div>
      </div>
      <div className="line-up-line" />
    </div>
  );
}

export default LineUpHeader;
