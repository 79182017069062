// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { useTranslation } from 'react-i18next';
import RainbowText from '../../common/rainbowText';

export default function DeliveryInfo(props) {
  const { t } = useTranslation();

  return (
    <div className="delivery-info-lottery-detail">
      <div className="delivery-info-content">
        <h1>{t('aboutDelivery')}</h1>
        <div className="line" />
        <h2>
          <RainbowText text={t('aboutDeliveryJp')} />
        </h2>
        <div className="delivery">
          <h3>
            <strong>{t('textTimeDelivery')}</strong>
          </h3>
          <div className="line" />
          <p>{t('descriptionTimeDelivery')}</p>
        </div>
        <div className="delivery">
          <h3>
            <strong>{t('textShippingMethod')}</strong>
          </h3>
          <div className="line" />
          <p>{t('descriptionShippingMethod')}</p>
        </div>
        <div className="delivery">
          <h3>
            <strong>配送手数料</strong>
          </h3>
          <div className="line" />
          <p className="note-time">
            <span style={{ display: 'block' }}>
              複数回購入でも累計で「20枚」まで1回分の配送手数料のご負担となります。
            </span>
            <span style={{ display: 'block' }} className="note-time-custom">
              ※同一くじに限ります。
            </span>
            <span style={{ display: 'block' }}>
              購入数が1回分の手数料でお届けできる個数を超えた場合、以降、上限の個数ごとに手
            </span>
            <span style={{ display: 'block' }}>
              数料が発生します。ただし、同額のクジポイントでポイントバック致します。
            </span>
            <span style={{ display: 'block' }} className="note-time-custom">
              ※クジポイントの有効期限は30日となります。
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
