// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

function PaymentMethod(props) {
  const history = useHistory();
  const pathLotteryTrial = '/purchase/trial';
  const pathSite = history.location.pathname;
  const { paymentMethod, data, getPaymentMethod } = props;
  const lotteryEvent = data?.lottery_event;
  const { t } = useTranslation();
  const search = queryString.parse(history?.location?.search);

  useEffect(() => {
    getPaymentMethod(
      Number(search?.payment_method)
        ? Number(search?.payment_method)
        : lotteryEvent?.payment_methods[0]?.id,
    );
  }, []);
  return (
    <>
      {lotteryEvent?.payment_methods?.length > 0 && (
        <div className="my-payment-method">
          <div className="title-inbox-sp">
            <strong className="title-inbox">{t('textPaymentMethodSection')}</strong>
          </div>

          <div>
            <div className="list-method my-box-purchase">
              <div className="title">
                <strong className="title-inbox">{t('textPaymentMethodSection')}</strong>
              </div>

              {pathSite === pathLotteryTrial ? (
                <div style={{ color: '#FF0000', whiteSpace: 'pre-wrap' }}>
                  {`お試しくじのため、\n支払い方法の選択は不要です。`}
                </div>
              ) : (
                <>
                  {lotteryEvent?.payment_methods?.map((method, index) => {
                    return (
                      <div
                        key={index}
                        className="form-check px-0 payment_method_credit"
                        onChange={(e) => getPaymentMethod(Number(e.target.value))}
                      >
                        <input
                          type="radio"
                          name="methodPayment"
                          value={method.id}
                          defaultChecked={Number(method.id) === Number(paymentMethod)}
                          id={method.name}
                        />
                        <label htmlFor={method.name}>{method.name}</label>

                        <img src={method?.payment_method_icon} alt="img" className="image-credit" />
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PaymentMethod;
