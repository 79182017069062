// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import styled from 'styled-components';
import TopHeader from '../common/topHeader';
import ScrollButton from './top/backToTop/ScrollButton';
import { Table } from 'react-bootstrap';

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: 30px;
  margin-bottom: 30px;
  color: #464646;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 20px;
  }
`;

const Content = styled.div`
  font-size: 14px;
  letter-spacing: 0.1em;
  line-height: 24px;
  margin-bottom: 20px;
  th {
    background-color: #f0f0f0;
    vertical-align: middle;
    width: 30%;
    color: #464646;
  }
  td {
    color: #464646;
  }
  @media screen and (max-width: 768px) {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 15px;
  }
`;

export default function Law() {
  return (
    <>
      <TopHeader title="特定商取引法に基づく表記" />
      <div className="main-content form-static">
        <Title>特定商取引法に基づく表記</Title>
        <Content>
          <Table bordered>
            <tbody>
              <tr>
                <th>事業者</th>
                <td>株式会社 CREST</td>
              </tr>
              <tr>
                <th>サイト（サービス）名</th>
                <td>KuZiPA!</td>
              </tr>
              <tr>
                <th>本社所在地</th>
                <td>東京都新宿区西新宿3丁目2-4 JRE西新宿テラス4F</td>
              </tr>

              <tr>
                <th>サイト責任者</th>
                <td>三上 政高</td>
              </tr>
              <tr>
                <th>支払い方法</th>
                <td>
                  以下のお支払い方法をご利用いただけます。
                  <br />
                  ■クレジットカード決済 <br />
                  VISA/MasterCard/JCB/AMEX/DINERS <br />
                  ■携帯キャリア決済
                  <br />
                  ドコモ、ソフトバンク
                  <br />
                  ■ウォレット決済
                  <br />
                  PayPay/楽天ペイ/Apple Pay（JCB/AMEX/VISA/Mastercard）/
                  <br />
                  Google Pay（JCB/AMEX/ SBPS）/メルカリ（メルペイネット決済）
                  <br />
                  ■海外決済
                  <br />
                  PayPal（※但し、ご利用と発送は日本国内に限られます。）
                  <br />
                  ※くじ（サービス）や商品（景品）によりお取り扱いやお支払い方法が異なる場合がございますので、詳細は各ページをご確認ください。
                </td>
              </tr>
              <tr>
                <th>商品代金以外の料金</th>
                <td>
                  ◆消費税及び地方消費税 <br />
                  ◆送料500円 <br />
                  ◆通信料
                </td>
              </tr>
              <tr>
                <th>お届け方法</th>
                <td>
                  配送 <br />
                  <br />
                  ■商品発送：商品発送完了後、「発送完了通知メール」、または「マイページ」内の「注文履歴」にて「配送会社」「送り状番号」をご案内しております。長期ご不在、ご住所不明、転居先不明等の返送理由に関わらず、再発送にかかる往復送料等はお客様のご負担となりますので、あらかじめご了承ください。{' '}
                  <br />
                  ■ご不在の場合の対応：商品お届け時にご不在の場合は、配送業者が投函する「ご不在連絡票」に従い、商品のお受け取り手続きをお願いします。発送完了後3日が経過しても初回配達がない場合は、住所不明の可能性がございますので、配送業者へご連絡ください。
                  <br />
                  ■保管期間：長期不在や住所不明で配送業者の保管期間内にお受け取りいただけなかった場合、商品はKuZiPA!に返送されます。返送から2ヶ月が経過した時点でご注文はキャンセルとさせていただきますので、お早めにKuZiPA!まで再発送のご依頼をお願いいたします。
                  <br />
                  ※再発送にかかる往復送料等はお客様のご負担となります。
                  <br />
                  ※商品（景品）のお届けは、日本国内のみとさせていただきます。
                </td>
              </tr>
              <tr>
                <th>発送時期とご連絡</th>
                <td>
                  くじ販売終了後、1～2ヶ月後 <br />
                  ※配送の日時指定は出来ません。
                </td>
              </tr>
              <tr>
                <th>不具合品等について</th>
                <td>
                  お届けした商品に不具合、商品違い又は数量不足等があった場合には、商品到着後7日以内に当社所定の窓口(kuzipa@crest-inc.net)までご連絡ください。速やかに交換等の対応をさせていただきます。（送料・手数料当社負担）{' '}
                  <br />
                  ※商品の不具合に関するご相談は、「商品（景品）に関するお問い合わせ」の各々の窓口(kuzipa@crest-inc.net)宛へお問い合わせください。
                  <br />
                  ※商品違い又は数量不足等に関するご相談は「商品（景品）に関するお問い合わせ」(kuzipa@crest-inc.net)へお問い合わせください。
                  <br />
                  ※商品は発送用ダンボール箱で発送します。発送用ダンボール箱に傷やつぶれがある場合でも、商品自体にダメージがなければ、商品及び商品パッケージの交換はできませんのでご了承ください。
                </td>
              </tr>
              <tr>
                <th>返品について</th>
                <td>
                  お客様都合でのキャンセル・交換・返品は受け付けておりません。 <br />
                  ※お客様都合での一方的なキャンセル・受取り拒否等があった場合、それ以降のお取引の受付等をお断りさせて頂く場合がありますので予めご了承ください。
                  <br />
                  ※商品に不良があった場合は、「商品（景品）に関するお問い合わせ」(kuzipa@crest-inc.net)にて交換等の対応をさせていただきます。
                  <br />
                </td>
              </tr>
              <tr>
                <th>その他の注意事項</th>
                <td>
                  ◆ご注文を受付けましたら、「ご注文確認メール」が自動送信されますので、必ず受信メールをご確認ください。数時間たっても「ご注文確認メール」が届かない場合は、必ず当社までご連絡(kuzipa@crest-inc.net)お願いします。{' '}
                  <br />
                  ◆メールアドレスや配送先などの確認のため、電話等でお客様にご連絡させていただくことがあります。日中にご連絡が取れるお電話番号をご入力ください。
                  <br />
                  ◆ドメイン指定について「kuzipa@crest-inc.net」のメールを許可してください。メール受信制限をしている場合、当店からのメールが届きません。
                  <br />
                  ◆未成年の方が商品を注文される場合は、保護者のご同意の上でご注文してください。ご注文が確定した場合は、保護者のご同意を得ているものとみなせていただきます。
                  <br />
                  ◆商品仕様や発送予定日は、予告なく変更になる場合があります。予めご了承ください。
                </td>
              </tr>
            </tbody>
          </Table>
        </Content>
      </div>
      <ScrollButton />
    </>
  );
}
