// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TOP_IMAGES } from '../../../assets/imgs';
import TButton from '../../components/common/button';
import RainbowText from '../../components/common/rainbowText';
import TopTitleKuzipa from '../../components/common/titleKuzipa';
import useMessage from '../../components/common/toast/UseMessage';
import ScrollButton from '../../components/pages/top/backToTop/ScrollButton';
import Bubble from '../../components/pages/top/bubble';
import Carousel from '../../components/pages/top/carousel/Carousel';
import ListLottery from '../../components/pages/top/listLottery/ListLottery';
import ListNews from '../../components/pages/top/listNews';
import TwitterTimeline from '../../components/pages/top/twitterTimeline';
import { TopApis } from '../../services/top';
import { useAppDispatch } from '../../store';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import { listsErrorNotShowToast } from '../../constants';
import JSCookie from 'js-cookie';

import './index.scss';

export default function Top() {
  const [listBanner, setListBanner] = useState([]);
  const [listLottery, setListLottery] = useState([]);

  const { openMessageError } = useMessage();

  const [listNews, setListNews] = useState([]);

  const history = useHistory();

  const dispatch = useAppDispatch();

  const token = JSCookie.get('token');

  const getListBanner = async () => {
    dispatch(showLoading());
    try {
      const res = await TopApis.getListBanner();
      const { data } = res;
      setListBanner(data);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  const getListLottery = async () => {
    dispatch(showLoading());
    try {
      const res = await TopApis.getListLottery();
      const { data } = res;
      setListLottery(data);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  const getListNews = async () => {
    dispatch(showLoading());
    try {
      const res = await TopApis.getListNews();
      const { data } = res;
      setListNews(data.list_news);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getListBanner();
    getListLottery();
    getListNews();
  }, []);

  return (
    <div className="top">
      <div className="top-banner-list">
        <Carousel listBanner={listBanner} />
      </div>
      <div className="banner">
        <div className="banner-content">
          <img
            alt="img"
            src={`${process.env.APP_S3}/${process.env.APP_DOMAIN.slice(8)}banner/banner.png`}
          />
        </div>
      </div>
      <div className="text-center">
        <div className="line-up-wrapper d-flex justify-content-center">
          <div className="line-up">LINE UP</div>
        </div>
        <RainbowText text="ラインナップ" />
        <TButton
          title="くじ一覧へ"
          className="primary gotoListLotteryBtn"
          onClick={() => history.push('/lottery')}
        />
      </div>
      <ListLottery listLottery={listLottery} />
      <div className="wrap-title-kuzipa">
        <TopTitleKuzipa />
      </div>
      <div className="bubble-wrapper">
        {token && (
          <Bubble
            img={TOP_IMAGES.Icons.MyCollectionIcon}
            className="bubble-myCollection"
            text="MYコレクションを知ろう!"
            action={() => history.push('/my-collection')}
          />
        )}
        <Bubble
          img={TOP_IMAGES.Icons.UserGuideIcon}
          className="bubble-useGuide"
          text="初めての方へ"
          action={() => history.push('/guide')}
        />
        <Bubble
          img={TOP_IMAGES.Icons.GoToBuyLotteryIcon}
          className="bubble-gotoBuy"
          text="オンラインくじを試そう"
          action={() => history.push('/trial')}
        />
      </div>
      {!token && (
        <div className="d-flex justify-content-center button-wrapper">
          <TButton
            className="danger me-3"
            title="新規会員登録"
            onClick={() => history.push('/register')}
          />
          <TButton className="info" title="ログイン" onClick={() => history.push('/login')} />
        </div>
      )}
      <div className="news-wrapper">
        <div className="news">
          <ListNews listNews={listNews} />
        </div>
        <div className="twitter">
          <TwitterTimeline />
        </div>
      </div>
      <ScrollButton />
    </div>
  );
}
