// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import LineUpHeader from './LineUpHeader';
import PreviewPhoto from './PreviewPhoto';

function LineUpItem(props) {
  const { prizeGroup } = props;
  const { prize } = prizeGroup;

  const [modalShow, setModalShow] = useState(false);
  const [listsPreview, setListsPreview] = useState([]);
  const [order, setOrder] = useState(0);

  useEffect(() => {
    const result = [];
    for (let i = 0; i < prize.length; i++) {
      for (let j = 0; j < prize[i].image.length; j++) {
        result.push({
          ...prize[i],
          imgThumbnail: prize[i].image[j].thumbnail_url,
          img: prize[i].image[j].image_url,
        });
      }
    }
    setListsPreview(result);
  }, [prize]);

  const handleOpenPreview = (index) => {
    setModalShow(true);
    setOrder(index);
  };
  return (
    <div>
      <LineUpHeader prizeGroup={prizeGroup} />

      <div className="win-rate-sp">
        <p className="mb-0">
          当せん確率
          <span>
            <strong>{prizeGroup.win_rate}</strong>/100
          </span>
        </p>
      </div>

      <p className="line-up-description">{prizeGroup.description}</p>

      <div className="line-up-content">
        <div className="line-up-content-image">
          <img src={prizeGroup.image[0].image_url} alt="img" className="line-up-image" />
        </div>
        <div className="line-up-thumbnail">
          {listsPreview.length > 0 &&
            listsPreview.map((prize, index) => {
              return (
                <div key={index} onClick={() => handleOpenPreview(index)}>
                  <div className="wrap-line-up-sup-thumb">
                    <Card.Img src={prize.imgThumbnail} className="line-up-sup-thumbnail" />
                  </div>
                  <p className="mb-0">{`${prizeGroup.rank_name}-${prize.number_order} ${prize.title}`}</p>
                </div>
              );
            })}
        </div>
        {prizeGroup.note && <div className="note">{prizeGroup.note}</div>}
      </div>
      <PreviewPhoto
        show={modalShow}
        onHide={() => setModalShow(false)}
        prizes={listsPreview}
        data={prizeGroup}
        order={order}
      />
    </div>
  );
}

export default LineUpItem;
