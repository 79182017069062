// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useEffect } from 'react';
import { JP } from '../../../assets/imgs';

function SystemTemplate(props) {
  useEffect(() => {
    var _bownow_cs_sid_1078537c6225d25b4f35 = document.createElement('script');
    _bownow_cs_sid_1078537c6225d25b4f35.charset = 'utf-8';
    _bownow_cs_sid_1078537c6225d25b4f35.src =
      'https://contents.bownow.jp/forms/sid_1078537c6225d25b4f35/trace.js';
    document.getElementsByTagName('head')[0].appendChild(_bownow_cs_sid_1078537c6225d25b4f35);
    // fadein-animation
    jQuery(window).on('load scroll', function () {
      jQuery('.slidein').each(function () {
        var elemPos = jQuery(this).offset().top,
          scroll = jQuery(window).scrollTop(),
          windowHeight = jQuery(window).height();

        if (scroll > elemPos - windowHeight + 100) {
          jQuery(this).addClass('scrollin');
        }
      });
    });

    jQuery(function ($) {
      $('a[href^="#"]').click(function () {
        var speed = 1000; // スクロール速度(ミリ秒)
        var href = $(this).attr('href');
        var target = $(href == '#' || href == '' ? 'html' : href);
        var position = target.offset().top;
        $('html').animate({ scrollTop: position }, speed, 'swing');
        return false;
      });
    });
  }, []);

  return (
    <>
      <header className="header">
        <div className="header-logo">
          <img src={JP.headerLogo} alt="CREST" />
        </div>
        <div className="header-btn">
          <a href="#contact">
            <p>お問い合わせ</p>
          </a>
        </div>
      </header>

      <main>
        <div className="fv">
          <div className="fv-inner">
            <div className="fv-img">
              <picture>
                <source srcSet={JP.fvImgSp} media="(max-width:767px)" />
                <img src={JP.fvImg} alt="fv-img" className="fv-img-custom" />
              </picture>
            </div>
          </div>
        </div>
        <div className="worry">
          <div className="contents-inner">
            <div className="inner-box worry-box">
              <div className="contents-title">
                <h2>
                  こんな<span className="fc-40b568">お悩み</span>ありませんか？
                </h2>
              </div>
              <div className="worry-subtitle slidein">
                <h3>IPはあるけど・・・</h3>
              </div>
              <div className="worry-flex">
                <div className="worry-block slidein worry-block1">
                  <div className="worry-block-number">
                    <p className="fc-f9be2d">01</p>
                  </div>
                  <div className="worry-block-img">
                    <img src={JP.worryImg1} alt="worry-img1" />
                  </div>
                  <div className="worry-block-text">
                    <p>
                      IPの新しい売り方を
                      <br />
                      探している
                    </p>
                  </div>
                </div>
                <div className="worry-block slidein worry-block2">
                  <div className="worry-block-number">
                    <p className="fc-40b568">02</p>
                  </div>
                  <div className="worry-block-img">
                    <img src={JP.worryImg2} alt="worry-img2" />
                  </div>
                  <div className="worry-block-text">
                    <p>
                      商品展開で
                      <br />
                      収益アップを検討している
                    </p>
                  </div>
                </div>
                <div className="worry-block slidein worry-block3">
                  <div className="worry-block-number">
                    <p className="fc-fe476b">03</p>
                  </div>
                  <div className="worry-block-img">
                    <img src={JP.worryImg3} alt="worry-img3" />
                  </div>
                  <div className="worry-block-text">
                    <p>
                      新しい施策を打つ予算や
                      <br />
                      人的リソースがない
                    </p>
                  </div>
                </div>
                <div className="worry-block slidein worry-block4">
                  <div className="worry-block-number">
                    <p className="fc-1e9ded">04</p>
                  </div>
                  <div className="worry-block-img">
                    <img src={JP.worryImg4} alt="worry-img4" />
                  </div>
                  <div className="worry-block-text">
                    <p>
                      システム開発をする
                      <br />
                      時間や予算がない
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="solution">
          <div className="contents-inner">
            <div className="solution-subtitle">
              <p>
                <span
                  className="fc-f9be2d"
                  style={{ backgroundImage: `url(${JP.solutionBalloon})` }}
                >
                  新収益
                </span>
                <span
                  className="fc-1e9ded"
                  style={{ backgroundImage: `url(${JP.solutionBalloon})` }}
                >
                  開発
                  <br />
                  リソース
                </span>
                <span
                  className="fc-fe476b"
                  style={{ backgroundImage: `url(${JP.solutionBalloon})` }}
                >
                  プロ
                  <br className="br-767" />
                  モーション
                </span>
              </p>
            </div>
            <div className="solution-inner">
              <div className="solution-text">
                <div className="solution-img">
                  <img src={JP.solutionImg} alt="solution-img" />
                </div>
                <div className="solution-img2">
                  <img src={JP.solutionImg2} alt="solution-img2" />
                </div>
                <div className="solution-img3">
                  <img src={JP.solutionImg3} alt="solution-img3" />
                </div>
                <p>
                  そんなお悩みを
                  <br />
                  <span className="fc-fe476b">オンラインくじ</span>で<br className="br-1200" />
                  解決します！
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="whats">
          <div className="contents-inner">
            <div className="contents-title">
              <h2>
                <span className="fc-ff6c02">オンラインくじ</span>とは？
              </h2>
            </div>
            <div className="whats-inner">
              <div className="whats-subtitle slidein">
                <h3>
                  <span>PC</span>や<span>スマホ</span>から
                  <span>
                    <br className="br-767" />
                    くじ方式で商品を購入できる
                  </span>
                  <br />
                  オンラインサービスです
                </h3>
              </div>
              <div className="whats-flex">
                <div className="whats-block slidein whats-block1">
                  <div className="whats-block-img bc-f9be2d">
                    <img src={JP.whatsImg1} alt="whats-img1" />
                  </div>
                  <div className="whats-block-text">
                    <p>スマホ・PCから</p>
                    <h4 className="fc-f9be2d">簡単に遊べる</h4>
                  </div>
                </div>
                <div className="whats-block slidein whats-block2">
                  <div className="whats-block-img bc-ff6c02">
                    <img src={JP.whatsImg2} alt="whats-img2" />
                  </div>
                  <div className="whats-block-text">
                    <p>ハズレなし！</p>
                    <h4 className="fc-ff6c02">100%当たる</h4>
                  </div>
                </div>
                <div className="whats-block slidein whats-block3">
                  <div className="whats-block-img bc-fe476b">
                    <img src={JP.whatsImg3} alt="whats-img3" />
                  </div>
                  <div className="whats-block-text">
                    <p>オリジナルグッズや</p>
                    <h4 className="fc-fe476b">スペシャル企画</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="start">
          <div className="contents-inner">
            <div className="contents-title">
              <h2 className="fc-fff">
                <span className="fc-fff">オンラインくじ</span>
                <br className="br-767" />
                始めてみませんか？
              </h2>
            </div>
            <div className="start-inner">
              <div className="start-subtitle slidein">
                <p className="fc-fff">
                  オンラインくじサービスを
                  <br className="br-423" />
                  導入することにより、
                  <br />
                  自社ブランドでサービスの運用が可能！
                </p>
              </div>
              <div className="start-img slidein">
                <div className="start-left">
                  <img src={JP.startLeft} alt="start-left" />
                </div>
                <div className="start-right">
                  <img src={JP.startRight} alt="start-right" />
                </div>
                <img src={JP.startImg} alt="start-img" />
              </div>
            </div>
          </div>
        </div>
        <div className="reason">
          <div className="contents-inner">
            <div className="contents-title">
              <h2>
                CRESTのオンラインくじシステムが
                <br className="br-767" />
                <span className="fc-ff6c02">選ばれる理由</span>
              </h2>
            </div>
            <div className="reason-inner">
              <div className="reason-subtitle slidein">
                <p>
                  サービスを成功に導く条件や
                  <br className="br-767" />
                  機能を網羅しています。
                </p>
              </div>
              <div className="reason-textbox slidein">
                <h3>
                  <span>システム提供のみ</span> でも{' '}
                  <span>
                    <br className="br-767" />
                    運用までおまかせ
                  </span>
                  <br />
                  どちらのお取り込みでも
                  <br className="br-767" />
                  実施可能です
                </h3>
              </div>
              <div className="reason-flex">
                <div className="reason-block slidein reason-block1">
                  <div className="reason-block-line bc-f9be2d" />
                  <div className="reason-block-img">
                    <img src={JP.reasonImg1} alt="reason-img1" />
                  </div>
                  <div className="reason-block-title">
                    <h4 className="fc-f9be2d">購入回数UP</h4>
                  </div>
                  <div className="reason-block-text">
                    <p>
                      購入したくじ商品とは別の自動抽選機能があるので、ユーザーの購入を促せます。
                    </p>
                  </div>
                </div>
                <div className="reason-block slidein reason-block2">
                  <div className="reason-block-line bc-fe476b" />
                  <div className="reason-block-img">
                    <img src={JP.reasonImg2} alt="reason-img1" />
                  </div>
                  <div className="reason-block-title">
                    <h4 className="fc-fe476b">購入単価UP</h4>
                  </div>
                  <div className="reason-block-text">
                    <p>
                      一定数のクジをひとまとめにしたり、本購入者に対して特典をつけたりすることも可能です。
                    </p>
                  </div>
                </div>
                <div className="reason-block slidein reason-block3">
                  <div className="reason-block-line bc-1e9ded" />
                  <div className="reason-block-img">
                    <img src={JP.reasonImg3} alt="reason-img1" />
                  </div>
                  <div className="reason-block-title">
                    <h4 className="fc-1e9ded">ギャンブル性の抑制</h4>
                  </div>
                  <div className="reason-block-text">
                    <p>
                      同一タイトルのくじを累計100枚引くごとに、好きな景品1個と交換できるチケットです。
                    </p>
                  </div>
                </div>
                <div className="reason-block slidein reason-block4">
                  <div className="reason-block-line bc-40b568" />
                  <div className="reason-block-img">
                    <img src={JP.reasonImg4} alt="reason-img1" />
                  </div>
                  <div className="reason-block-title">
                    <h4 className="fc-40b568">転売抑止</h4>
                  </div>
                  <div className="reason-block-text">
                    <p>
                      不要な商品は破棄することで新たにくじを引けるポイントに変換することも可能で長期的な利用につながります。
                    </p>
                  </div>
                </div>
                <div className="reason-block slidein reason-block5">
                  <div className="reason-block-line bc-ff6c02" />
                  <div className="reason-block-img">
                    <img src={JP.reasonImg5} alt="reason-img1" />
                  </div>
                  <div className="reason-block-title">
                    <h4 className="fc-ff6c02">豊富な決済手段</h4>
                  </div>
                  <div className="reason-block-text">
                    <p>
                      クレジットカード、キャリア決済、電子マネーなどさまざまな決済手段を用意しております。
                    </p>
                  </div>
                </div>
                <div className="reason-block slidein reason-block6">
                  <div className="reason-block-line bc-f9be2d" />
                  <div className="reason-block-img">
                    <img src={JP.reasonImg6} alt="reason-img1" />
                  </div>
                  <div className="reason-block-title">
                    <h4 className="fc-f9be2d">コレクション欲</h4>
                  </div>
                  <div className="reason-block-text">
                    <p>購入した商品を一覧で表示＆簡単にシェア可能。</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="service">
          <div className="contents-inner">
            <div className="service-inner">
              <div className="service-textbox slidein">
                <h2>
                  面倒な企画・商品発送まで
                  <br />
                  お任せください！
                </h2>
              </div>
              <div className="service-subtitle slidein">
                <p>
                  様々なIP事業を手掛けるCRESTが
                  <br />
                  <span>企画〜発送までワンストップ</span>の<br className="br-423" />
                  サービスをご提供します。
                </p>
              </div>
              <div className="service-box slidein">
                <picture>
                  <source srcSet={JP.serviceImgSp} media="(max-width:767px)" />
                  <img src={JP.serviceImg} alt="service-img" />
                </picture>
              </div>
              <div className="service-option">
                <div className="service-subtitle">
                  <h3>オプション</h3>
                </div>
                <div className="service-flex">
                  <div className="service-block slidein">
                    <div className="service-block-flex">
                      <div className="service-block-img">
                        <img src={JP.optionImg1} alt="option-img1" />
                      </div>
                      <div className="service-block-title">
                        <h4>
                          商品デザイン
                          <br />
                          クリエイティブ
                        </h4>
                      </div>
                    </div>
                    <div className="service-block-line" />
                    <div className="service-block-text">
                      <p>
                        グループ会社とも連携し、
                        <span>絵寄せオリジナルイラストなどを用いた商品デザインが可能</span>
                      </p>
                    </div>
                  </div>
                  <div className="service-block slidein">
                    <div className="service-block-flex">
                      <div className="service-block-img">
                        <img src={JP.optionImg2} alt="option-img2" />
                      </div>
                      <div className="service-block-title">
                        <h4>
                          クロス
                          <br />
                          メディア展開
                        </h4>
                      </div>
                    </div>
                    <div className="service-block-line" />
                    <div className="service-block-text">
                      <p>
                        ECサイトやリアル店舗（準備中）とのコラボなど
                        <br className="br-pc-1200" />
                        <span>「オンラインくじ」以外のメディアコラボ展開</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="example">
          <div className="contents-inner">
            <div className="contents-title">
              <h2 className="fc-fff">
                <span className="fc-fff">売上</span>イメージ
              </h2>
            </div>
            <div className="example-inner slidein">
              <div className="example-box-left">
                <img src={JP.exampleImg1} alt="example-img1" />
              </div>
              <div className="example-box-right">
                <img src={JP.exampleImg2} alt="example-img2" />
              </div>
              <div className="example-hint">
                <img src={JP.exampleHint} alt="example-hint" />
                <span>スワイプで確認</span>
              </div>
              <div className="example-box">
                <img src={JP.exampleTable} alt="example-table" />
              </div>
            </div>
          </div>
        </div>
        <div className="faq">
          <div className="contents-inner">
            <div className="contents-title">
              <h2>よくある質問</h2>
            </div>
            <div className="faq-inner">
              <div className="faq-block">
                <div className="faq-question">
                  <p>導入するにはどのような費用がかかりますか？</p>
                </div>
                <div className="faq-answer">
                  <p>
                    初期費用およびシステム利用料がかかります。ただし初期費用は条件によって無償となる場合もございます。詳細はお問い合わせください。
                  </p>
                </div>
              </div>
              <div className="faq-block">
                <div className="faq-question">
                  <p>自社サービスとの連携方法は？</p>
                </div>
                <div className="faq-answer">
                  <p>API連携などで既存サービスにオンラインくじのシステムをご導入いただけます。</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contact" id="contact">
          <div className="contents-inner">
            <div className="contact-box">
              <div className="contents-title">
                <h2>
                  <span>お問い合わせ</span>
                </h2>
              </div>
              <script id="_bownow_cs_sid_1078537c6225d25b4f35"></script>
            </div>
          </div>
        </div>
      </main>

      <footer className="footer">
        <div className="footer-inner">
          <div className="footer-logo">
            <img src={JP.footerLogo} alt="CREST" />
          </div>
          <div className="footer-block">
            <div className="footer-link">
              <ul>
                <li>
                  <a href="https://www.crest-inc.net/" target="_blank" rel="noopener noreferrer">
                    運営会社について
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.crest-inc.net/privacy-policy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    プライバシーポリシー
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-copyright">
              <p>@CREST 2022</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default SystemTemplate;
