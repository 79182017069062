// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useEffect } from 'react';
import TopHeader from '../../components/common/topHeader';
import { GUIDE } from '../../../assets/imgs';
import TopTitleKuzipa from '../../components/common/titleKuzipa';
import './styles.scss';
import Tutorial from '../../components/pages/guide/Tutorial';
import RainbowText from '../../components/common/rainbowText';
import Step from '../../components/pages/guide/Step';
import TButton from '../../components/common/button';
import ScrollButton from '../../components/pages/top/backToTop/ScrollButton';
import { MaintainApi } from '../../services/maintain';
import { useHistory } from 'react-router-dom';

function UserGuide(props) {
  const history = useHistory();
  useEffect(async () => {
    const res = await MaintainApi.getMaintainData();
    const { data } = res;
    if (data?.status) {
      history.push('/maintain');
    }
  }, []);
  return (
    <>
      <TopHeader title="KuZiPA!とは？" />
      <div className="wrap_user_guide">
        <div className="top_guide">
          <TopTitleKuzipa />
          <div className="description-title">
            <p className="mb-0">5つの特徴</p>
            <div className="description-line">
              <img src={GUIDE.firstLine} alt="img" className="line-one" />
              <img src={GUIDE.secondLine} alt="img" className="line-two" />
              <img src={GUIDE.lastLine} alt="img" className="line-three" />
            </div>
          </div>

          <div className="tutorial-guide">
            <Tutorial
              image={GUIDE.icon1}
              textAbove="スマホ・PCから"
              text="簡単に遊べる"
              status="PHONE"
            />
            <Tutorial
              image={GUIDE.icon2}
              textAbove="ハズレなし！"
              text="100%当たる"
              status="FLOWER"
            />
            <Tutorial
              image={GUIDE.icon3}
              textAbove="オリジナルグッズや"
              text="スペシャル企画"
              status="BAG"
            />
            <Tutorial
              image={GUIDE.icon4}
              textAbove="Myコレクション"
              text="購入履歴/ギャラリー機能など"
              status="T_SHIRT"
            />
            <Tutorial
              image={GUIDE.icon5}
              textAbove="＋On Challenge"
              text="うれしいダブルチャンス"
              status="HAT"
            />
          </div>

          <div>
            <ul className="text-note">
              <li>
                +On
                Challengeとは、購入したくじでもらえる商品とは別に、自動抽選されて当せんする可能性のある賞です。
                <br />
                +On Challenge が行われないくじもあります。
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="step-guide">
        <div className="title-step-guide">
          <h1 className="text-center">How to Play</h1>
          <div className="line" />
          <h2 className="text-center">
            <RainbowText text="あそびかた" />
          </h2>
        </div>
        <div className="wrap-step-content">
          <div className="step-content">
            <Step
              className="step-first"
              step="Step 1"
              imageTab={GUIDE.tabPink}
              image={GUIDE.imageStep1}
              text="会員登録・ログイン"
              textDescription=""
              description={`くじを購入するにはアカウントが必要となります。\nアカウントをお持ちでない方は新規会員登録をお願いします。`}
            />
            <Step
              className="step-second"
              step="Step 2"
              imageTab={GUIDE.tabGreen}
              image={GUIDE.imageStep2}
              text="くじを引く"
              textDescription="(数量の決定・決済方法を選択)"
              description={`引きたいくじを選んだら、枚数と決済方法を選択します。`}
            />
            <Step
              className="step-three"
              step="Step 3"
              imageTab={GUIDE.tabOrange}
              image={GUIDE.imageStep3}
              text={`当たった商品を\n確認する`}
              textDescription=""
              description={`決済後、すぐに抽選となります。くじの結果、当せんした商品が表示されます。返品・キャンセルは不可ですのでご注意ください。`}
            />
            <Step
              className="step-four"
              step="Step 4"
              imageTab={GUIDE.tabBlue}
              image={GUIDE.imageStep4}
              text="商品を受け取る"
              textDescription=""
              description={`登録されたお届け先へ当せん商品が届きます。\nお届け目安時期については、くじ概要をチェックしてください。`}
            />
          </div>

          <div className="note-step">
            <ul>
              <li>重複してしまった商品は、破棄することも可能です。</li>
              <li>
                不要な商品を破棄することで、新たにくじを引けるポイントに変換することも可能です。
              </li>
            </ul>
          </div>
        </div>
        <div className="btn-guide text-center">
          <TButton
            title="お試しクジを引く"
            className="danger"
            onClick={() => history.push('/trial')}
          />
        </div>
      </div>
      <ScrollButton />
    </>
  );
}

export default UserGuide;
