// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { useTranslation } from 'react-i18next';
import LineUpItem from './LineUpItem';
import { ON_CHALLENGE } from '../../../constants';
import RainbowText from '../../common/rainbowText';

export default function LineUpAward(props) {
  const { data } = props;
  const prizeGroups = data.prize_groups;
  const { t } = useTranslation();
  return (
    <>
      {prizeGroups.length > 0 && (
        <div className="line-up-award">
          <div className="text-center">
            <h4 className="my-color-black-200">{t('lineUp')}</h4>
            <div className="line" />
            <RainbowText text={t('productLineUp')} />
          </div>

          {prizeGroups
            .filter((rank) => rank.rank_name !== ON_CHALLENGE)
            .map((prizeGroup, index) => {
              return <LineUpItem key={index} prizeGroup={prizeGroup} />;
            })}
        </div>
      )}
    </>
  );
}
