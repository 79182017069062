// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ON_CHALLENGE } from '../../../constants';
import WChanceCampaignPeriod from './WChanceCampaignPeriod';
import wChange from '../../../../assets/imgs/lotteryDetail/wChange.png';
import { LOTTERY_IMAGE } from '../../../../assets/imgs';
import PreviewPhoto from './PreviewPhoto';
import OnChallengeText from '../../common/OnChallengeText';

export default function InfoWChance(props) {
  const { data } = props;
  const prizeGroups = data.prize_groups;
  const prizeWChances = prizeGroups?.filter((rank) => rank.rank_name === ON_CHALLENGE);
  const { t } = useTranslation();
  const COLORS = ['#F55076', '#40B569', '#FF6C00', '#1D9DED', '#464646'];

  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      {prizeWChances.length > 0 && (
        <div className="w-chance-award">
          <div className="w-chance-title">
            <img src={wChange} alt="bg-wChange" />
            <h1>
              <OnChallengeText />
            </h1>
          </div>

          <div className="w-chance-award-content">
            <div className="text-center shortDescriptionImg">
              <img
                src={LOTTERY_IMAGE.imgDescriptionWChange}
                alt="img"
                className="shortDescriptionImgPC"
              />
              <img
                src={LOTTERY_IMAGE.imgDescriptionWChangeSP}
                alt="img"
                className="shortDescriptionImgSP"
              />
              <div className="text-pc">{t('wChanceShortDescription')}</div>
              <div className="text-sp">{t('wChanceShortDescriptionSP')}</div>
            </div>

            {prizeWChances[0].image[0].image_url && (
              <div className="text-center wrap-image-w-chance">
                <img
                  src={prizeWChances[0].image[0].image_url}
                  alt="img"
                  className="main-image cursor-pointer"
                  onClick={() => setModalShow(true)}
                />
              </div>
            )}

            <div className="description-period">
              {prizeWChances[0].prize.map((prize, index) => {
                return (
                  <div key={index}>
                    <p className="d-flex align-items-center">
                      <span
                        className="my-tag-des-period"
                        style={{
                          background: COLORS[index % 5],
                        }}
                      >
                        {t('periodName').replace('param', prize.number_order)}
                      </span>
                      <strong>
                        <span className="title-period">{prize.title}</span>

                        {prize.quantity_show !== null &&
                          t('quantityShowPeriod').replace('param', prize.quantity_show)}
                      </strong>
                    </p>
                  </div>
                );
              })}
            </div>

            <div className="w-chance-campaign-period">
              <WChanceCampaignPeriod prizes={prizeWChances[0].prize} />
            </div>
          </div>
        </div>
      )}
      <PreviewPhoto
        show={modalShow}
        onHide={() => setModalShow(false)}
        image={prizeWChances[0]?.image[0].image_url}
      />
    </>
  );
}
